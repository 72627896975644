import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../package.json";
import { ImageGrid } from "../src/index";
import { ImageGridExample, ImageGridSmallExample, ImageGridTargetHeightExample, ImageGridBrokenExample } from "../src/Examples";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "image-grid"
    }}>{`Image-grid`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <p>{`Image grid to display multiple images in a grid.`}</p>
    <h2 {...{
      "id": "image-array-specs"
    }}>{`Image array specs`}</h2>
    <p>{`The component takes in an array of images. An example array is below:`}</p>
    <pre><code parentName="pre" {...{}}>{`const images = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      alt: "This is alt text"
    },
    {
      src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
      alt: "A wide image"
    }
  ];
`}</code></pre>
    <p>{`If no alt is given, the alt will be set blank ("").`}</p>
    <Playground __position={1} __code={'<ImageGridSmallExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ImageGrid,
      ImageGridExample,
      ImageGridSmallExample,
      ImageGridTargetHeightExample,
      ImageGridBrokenExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ImageGridSmallExample mdxType="ImageGridSmallExample" />
    </Playground>
    <pre><code parentName="pre" {...{}}>{`export const ImageGridSmallExample = () => {
  const images = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      alt: "This is alt yo",
    },
    {
      src: "https://source.unsplash.com/collection/190727/1600x900",
      alt: "Image about stuff",
    },
    {
      src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
      alt: "More alt stuff",
    },
    {
      src: "https://source.unsplash.com/random/400x400",
      alt: "There should always be alt",
    },
    {
      src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
      alt: "A wide image",
    },
  ];

  return (
    <SmallContainer>
      <ImageGrid
        images={images}
        direction={"row"}
        columns={2}
        maxImages={3}
        onClickImage={({ image, index, event }) => {
          console.log(image, index, event);
        }}
      />
    </SmallContainer>
  );
};

const SmallContainer = styled.div\`
  width: 400px;
\`;
`}</code></pre>
    <Playground __position={2} __code={'<ImageGridExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ImageGrid,
      ImageGridExample,
      ImageGridSmallExample,
      ImageGridTargetHeightExample,
      ImageGridBrokenExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ImageGridExample mdxType="ImageGridExample" />
    </Playground>
    <h3 {...{
      "id": "image-grid-with-target-row-and-max-image-height"
    }}>{`Image Grid with Target Row and Max Image Height`}</h3>
    <pre><code parentName="pre" {...{}}>{`  <ImageGrid
    images={images}
    maxImages={3}
    maxImageHeight="208px"
    targetRowHeight={1}
    onClickImage={({ image, index, event }) => {
      console.log(image, index, event);
    }}
  />
`}</code></pre>
    <Playground __position={3} __code={'<ImageGridTargetHeightExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ImageGrid,
      ImageGridExample,
      ImageGridSmallExample,
      ImageGridTargetHeightExample,
      ImageGridBrokenExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ImageGridTargetHeightExample mdxType="ImageGridTargetHeightExample" />
    </Playground>
    <h2 {...{
      "id": "broken-images"
    }}>{`Broken Images`}</h2>
    <p>{`If an image encounters an error on loading, an error state component will be rendered in place.`}</p>
    <Playground __position={4} __code={'<ImageGridBrokenExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ImageGrid,
      ImageGridExample,
      ImageGridSmallExample,
      ImageGridTargetHeightExample,
      ImageGridBrokenExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ImageGridBrokenExample mdxType="ImageGridBrokenExample" />
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <p>{`Full react-photo-gallery API is supported. See full list here: `}<a parentName="p" {...{
        "href": "http://neptunian.github.io/react-photo-gallery/api.html"
      }}>{`http://neptunian.github.io/react-photo-gallery/api.html`}</a></p>
    <Props of={ImageGrid} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      